.track-error-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.track-error-content{
    color: red;
    font-size: 30px;
    text-align: center;
}

.track-error-close{
    width: 75px;
    height: 25px;
    margin-top: 20px;
}