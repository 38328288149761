body {
    color: white;
    justify-content: center;
    align-items: center;
}

.trkInput{
    width: 300px;
    height: 36px;
}

.trkBtn{
    text-decoration: none;
    padding: 8px 15px;
    margin: 10px;
    background-color: #5f6e9f;
    color: #f3f3ef;
    cursor: pointer;
    outline: none;
    border: none;
    width: 70px;
}

.trk-container input {
    margin: 10px 0;
}

@media (max-width: 700px){
    .trk-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}