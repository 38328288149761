/* body{
    display: flex;
    justify-content: center;
    align-items: center;
} */

.home-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.intro-text{
    width: 700px;
    text-align: justify;
    margin: 50px 20px 20px 50px;
}

.intro-text h1 {
    font-size: 25px;
    margin: 10px 0;
    text-align: left;
}

.intro-text p {
    font-size: 16px;
    margin: 10px 0;
    text-align: justify;
}

.carriers{
    display: flex;
    margin-right: 100px;
}

.carrier-pickups-container{
    margin: 50px 0 50px 50px;
    border: 2px solid gray;
    padding: 15px 20px;
    background-color: #252525;
    width: 374px;
}

.carrier-pickups-container h1{
    font-size: 25px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #33373a;
    padding: 5px 10px;
}

.carrier-pickups{
    width: 330px;
}

.carrier-pickups img{
    width: 120px;
    margin: 5px 0;
}

.carrier-pickups-text{
    display: flex;
    background-color: #333333;
    padding: 0px 10px;
}

.carrier-pickups-text h2{
    font-size: 20px;
}

.carrier-pickups-text h3{
    margin-left: auto;
    font-size: 20px;
}

@media (max-width: 1024px){
    .carriers{
        display: flex;
        flex-direction: column;
        margin-right: 0px;
        align-items: center;
    }

    .carrier-pickups-container{
        margin: 50px 0 50px 0px;
    }

    .intro-text{
        margin: 50px 0px 20px 0px;
    }
}

@media (max-width: 750px){
    .intro-text{
        width: 90vw;
    }
}
