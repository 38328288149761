.contact-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.active-day{
    color: #4df077;
}

.map2d,
.map3d {
    width: 50vw;
    height: 40vh;
}

.map2d{
    margin-top: 20px;
}

.contact-container h1{
    font-size: 2rem;
    text-align: center;
}

.contact-container h2{
    font-size: 1.5rem;
    text-align: center;
}

.shop-hours{
    display: flex;
    border: 1px solid gray;
    margin-top: 20px;
    padding: 50px;
}

.shop-hours-week h2{
    text-align: left;
}

.shop-hours-hours h2{
    text-align: right;
    margin-left: 50px;
}

.driving-instructions{
    border: 1px solid white;
    padding: 10px 20px;
    background-color: lightblue;
    text-decoration: none;
    color: black;
    margin-top: 30px;
}

@media (max-width: 1000px){
    .map2d,
    .map3d {
        width: 90vw;
        height: 50vh;
    }

    .contact-container h1{
        font-size: 1.5rem;
        text-align: center;
        text-wrap: wrap;
    }
}