.about-container{
    display: flex;
    justify-content: center;
}

.about-info{
    text-align: justify;
    width: 50vw;
}

@media (max-width: 960px){
    .about-info{
        text-align: justify;
        width: 75vw;
    }
}