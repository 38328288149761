.container {
    padding: 0 24px;
  }

.tabs {
    display: flex;
    border-bottom: 1px solid #c6c6c6;
}

.tabs-content{
    width: 1000px;
}

.tab-button {
    padding: 8px 16px;
    border-radius: 0;
    border: none;
    background: transparent;
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
    color: white;
}

.tab-button:hover {
    border-bottom: 2px solid #cbcb41;
}

.tab-button.active {
    border-bottom: 2px solid #ecf68a;
    color: #ecf68a;
    font-weight: 700;
}

.tab-page {
    padding: 24px 16px 16px 16px;
    display: none;
}

.tab-page.active {
    display: block;
}

.tab-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab-img{
    width: 815px;
    height: 300px;
}

.tabs-ul {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.tab-content-container h1{
    font-size: 30px;
    margin: 10px 0;
    text-align: left;
}

.tab-content-container h2{
    font-size: 20px;
    margin: 10px 0;
    text-align: left;
}

.tab-content-container p{
    font-size: 16px;
    margin: 5px 0;
    text-align: left;
}

.tab-content-container h3{
    font-size: 20px;
    margin: 10px 0;
    text-align: left;
}

@media (max-width: 1017px){
    .tabs{
        justify-content: center;
        align-items: center;
    }

    .tab-img{
        width: 80vw;
        height: auto;
    }

    .tabs-content {
        width: 90vw;
    }

    .tab-content-container h1{
        font-size: 25px;
        text-align: center;
    }

    .tab-content-container h2{
        text-align: center;
    }
}